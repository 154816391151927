import React, { useEffect, useState } from 'react';
import Typography from "@material-ui/core/Typography";
import { IBuildData, IBuildDataCard } from '../types';
import { BuildItem } from './components/build-item';
import api from './services/api';

const delay = 10;
let timer: NodeJS.Timeout | undefined = undefined;

const BuildPage = () => {
  const [buildProductionData, setBuildProductionData] = useState<IBuildDataCard>()
  const [buildStagingData, setBuildStagingData] = useState<IBuildDataCard>()

  useEffect(() => {
    if(timer) clearTimeout(timer);
    getBuildData()

    return () => {
      if(timer) clearTimeout(timer);
    }
  }, [])

  useEffect(() => {
    const inProgress =
      buildProductionData?.deploy?.inProgress ||
      buildStagingData?.deploy?.inProgress

    if(inProgress) {
      if(!timer)
        timer = setInterval(() => getBuildData(), delay * 1000);
    } else if(timer) {
       clearTimeout(timer);
       timer = undefined
    }

  }, [
    buildProductionData?.deploy?.inProgress,
    buildStagingData?.deploy?.inProgress
  ])

  const getBuildData = () => {
    api({method: 'GET', url: ''})
    .then((data: IBuildData) => {
      const buildProductionDataRes = {
        build: data?.productionBuild || buildProductionData?.build || undefined,
        deploy: data?.productionDeploy || buildProductionData?.deploy || undefined,
      } as IBuildDataCard
    
      const buildStagingDataRes = {
        build: data?.stagingBuild || buildStagingData?.build || undefined,
        deploy: data?.stagingDeploy || buildStagingData?.deploy || undefined
      } as IBuildDataCard

      setBuildProductionData(buildProductionDataRes)
      setBuildStagingData(buildStagingDataRes)
    })
    .catch()
  }

  const onBuildTrigger = () => {
    getBuildData()
  }
  
  return (
    <>
      <Typography variant="h1">Teacher Directory Builder</Typography>
      <BuildItem 
        type="production"
        title="Teacher Directory Production"
        buildData={buildProductionData} 
        loading={!!buildProductionData?.deploy?.inProgress} 
        siteUrl="https://production-td.holdenqigong.com/teacher-directory/"
        buildWebhookUrl="https://api.cloudflare.com/client/v4/pages/webhooks/deploy_hooks/2c71a9e9-a441-4e64-9d57-18447ea14828"
        onBuildTrigger={onBuildTrigger}
      />

      <BuildItem 
        type="staging"
        title="Teacher Directory Staging"
        buildData={buildStagingData} 
        loading={!!buildStagingData?.deploy?.inProgress} 
        siteUrl="https://staging-td.holdenqigong.com/teacher-directory/"
        buildWebhookUrl="https://api.cloudflare.com/client/v4/pages/webhooks/deploy_hooks/a175ef75-5a9e-4c1f-9be3-e437f2cb3d2e"
        onBuildTrigger={onBuildTrigger}
      />
    </>
  );
}

export default BuildPage;
