import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => {
  return createStyles({
    wrapper: {
      maxWidth: 600,
      margin: '0 auto',
      marginTop: '15vh'
    },
    title: {
      color: '#EAB54D'
    },
    submitButton: {
      display: 'flex',
      marginLeft: 'auto',
      padding: '0 40px'
    }
  })
});