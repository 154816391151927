
import React from "react"
import { Card as CardItem} from "@influencemachinesllc/material-ui-components";
import { useStyles } from "./mui-styles";


export interface IProps {
  children: JSX.Element
}

export const Card = (props: IProps) => {
  const classes = useStyles();

  return (
    <CardItem wrapperClass={classes.cardWrapper}>
      {props.children}
    </CardItem>
  )
}
