export const getDate = (date: string | undefined) => {
    if(date) {
        const newDate = new Date(date)
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' } as any;
        const formattedDate = newDate.toLocaleDateString("en-US", options)
        return formattedDate
    } else {
        return ''
    }
}

export  const getTime = (date: string | undefined) => {
    if(date) {
        const newDate = new Date(date)
        const formattedDate = newDate.toLocaleTimeString('en-US',  { hour: '2-digit', minute: '2-digit' })

        return formattedDate
    } else {
        return '0:00'
    }
}

export const getDurationTime = (time: number | string | undefined) => {
    const t = typeof time === 'string' ? Number(time) : time;

    if(!t || isNaN(t)) return '0:00';

    const date = new Date(0);
    date.setSeconds(t);
    return date.toLocaleTimeString('en-US',  { minute: '2-digit', second: '2-digit' })
}