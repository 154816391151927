import React, { useState, useEffect, ChangeEvent } from "react";
import { FormInput } from "@influencemachinesllc/material-ui-components";

import classNames from "classnames";
import { IValidationData } from "../../../types";

export interface IProps {
  id: string;
  type: string;
  name: string;
  label?: string;
  placeholder?: string;
  className?: string;
  noErrors?: boolean;
  onKeyUp?: () => void;
  onChange?: (event: string) => void;
  validate?: string[];
  confirmationValue?: string;
  disabled?: boolean;
  isHalfStyle?: boolean;
  defaultValue?: string;
  isValid?: (validationData: IValidationData) => void;
}

export default function Input(props: IProps) {
  const [canValidate, setValidate] = useState<boolean>(false) 
  const [value, setValue] = useState<string | undefined>(props.defaultValue || undefined) 
  const [focused, setFocused] = useState<string | undefined>('')

  const {
    id,
    placeholder,
    label,
    type,
    name,
    onKeyUp,
    className,
    disabled
  } = props;

  useEffect(() => {
    const newVal = value || props.defaultValue
    if(newVal)
      setValue(value || props.defaultValue)
  }, [value, props.defaultValue])

  // useEffect(() => {
  //   validateValue(value)
  // }, [value])

  useEffect(() => {}, [value, focused, canValidate])
  
  const onBlur = () => setValidate(!!value)
  const onFocus = () => setFocused(name)
  const validationCond = (): boolean => (focused === name && canValidate);
  
  const validateValue = (value: string | undefined): string[] => {
    const errors: string[] = []

    if(props.validate) {
      props.validate?.forEach((
        type: string
      ) => {
        switch (type) {
          case 'required': {
            const inputValue = value && value.trim();
            const err = !inputValue || inputValue === '' 
            if(err)
              errors.push('Field is required')
            break;
          }
          case 'password': {
            const inputValue = value && value.trim();
            const err = inputValue && inputValue.length < 6
            if(err)
              errors.push('Invalid Password')
            break;
          }
          default:
            break;
        }
      })
    }

    if(props.isValid) {
      const errLength = errors?.length || 0
      props.isValid({ name: props.name, valid: errLength < 1})
    }

    return errors
  }

  const onDataChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);

    if(props.onChange)
      props.onChange(event.currentTarget.value);
  }

  const errMessage = (value: string | undefined) =>  validateValue(value)?.[0]
  const hasErrors = (value: string | undefined) =>  (validationCond() && errMessage(value))
  const showErrors = (value: string | undefined) => (!props.noErrors && errMessage(value) !== undefined) || hasErrors(value)

  return (
    <div data-testid="input-wrapper" className={classNames(className)}>
      <FormInput 
        label={label}
        data-testid={id}
        name={name}
        autoComplete="off"
        placeholder={placeholder}
        type={type}
        value={value || ''}
        onKeyUp={onKeyUp}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onDataChange}
        disabled={disabled}
        style={{marginBottom: 0}}
        showError={!!showErrors(value)}
        errorMessage={errMessage(value)}
      />
    </div>
  );
}
