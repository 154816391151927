/* eslint-disable no-unused-vars */

import axios, { AxiosRequestConfig } from 'axios'

export const api = ({ method, url, data }: AxiosRequestConfig) => {
  const token = localStorage.getItem('token')

  const appApiUrl = process.env.REACT_APP_API_URL
  const headers = token ? {
    'Authorization': token
  } : {}
  const requestUrl = (appApiUrl || '') + url
  return axios({ method, headers, url: requestUrl, data }).then((res) => res.data)
}

export default api
