import React, { useEffect, useState } from 'react';

import { Container, ThemeProvider } from '@influencemachinesllc/material-ui-components';
import { SignInForm } from './components/signInForm';
import BuildPage from './BuildPage';

import { CssBaseline } from "@material-ui/core";
import theme from './services/material-theme';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(false)
    return () => setLoading(true)
  }, [])


  useEffect(() => {
    if(!localStorage.getItem('token') && !isLoggedIn) 
      setIsLoggedIn(false)
    
    if(localStorage.getItem('token') && !isLoggedIn)
      setIsLoggedIn(true)
  }, [isLoggedIn])

  
  const logout = () => {
    localStorage.clear()
    setIsLoggedIn(false)
  }

  const onSignIn = () => {
    const token = !!localStorage.getItem('token')
    if(token)
      setIsLoggedIn(true)
  }


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <>
          {isLoggedIn && ( 
            <AppBar position="static">
              <Toolbar>
                <Button onClick={logout} style={{marginLeft: 'auto'}} color="inherit">Logout</Button>
              </Toolbar>
            </AppBar>
          )}
          <Container spaceTop spaceBottom>
            {!loading ? (
              <>
                {isLoggedIn ? (
                  <BuildPage />
                ) : (
                  <SignInForm onSignIn={onSignIn}/>
                )}
              </>
            ) : <Grid className="loader-wrapper"><div className="loader"/></Grid>}
          </Container>
        </>
    </ThemeProvider>
  );
}

export default App;
