
import React, { useEffect, useState } from "react"
import { Card } from "../card";

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import api from "../../services/api";
import { IBuildDataCard } from '../../../types'
import { getDate, getTime } from "../../services/time";

import classNames from "classnames";
import { useStyles } from "./mui-styles";


export interface IProps {
  type: string,
  title: string,
  buildData: IBuildDataCard | undefined,
  loading: boolean,
  buildWebhookUrl: string | undefined,
  siteUrl: string,
  onBuildTrigger?: () => void
}

export const BuildItem = (props: IProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(props.loading)
  }, [props.loading])

  const triggerBuild = () => {
    setLoading(true)

    api({ method:'POST', url: `/start-${props.type}`, data: {}})
    .then(() => {
      triggerWebhook()
    })
    .catch(() => {
      setLoading(false)
    })
  }

  const triggerWebhook = () => {
    const buildWebhookUrl = props?.buildWebhookUrl || ''

    fetch(buildWebhookUrl, { method: 'POST', mode: 'no-cors' }).then(() => {
      if(props?.onBuildTrigger)
        props.onBuildTrigger()
    })
    .catch(() => {
      setLoading(false)
    })
  }

  const deployed = !!props.buildData?.deploy?.success;

  return  props?.buildData ? (
    <Card>
      <Grid container className={classes.wrapper}>
        <Grid className={classes.leftWrapper}  container direction='column' item xs={9}>
          <Link href={props.buildData?.deploy?.deployPreviewUrl ? `${props.buildData?.deploy?.deployPreviewUrl}/teacher-directory` : props.siteUrl} target="_blank" className={classes.link} underline="none"><Typography variant="h3">{props.title}</Typography></Link>

          {props.loading ? (
            <Box component="div" className={classNames(classes.box, classes.timingWrapper, classes.timingWrapperLoading)}>
              <div className={classNames("loader", classes.marginRightS)}/>
              <Typography className={classes.buildInText} variant="subtitle2">Build started at {getTime(props.buildData?.deploy?.startTime || '')}</Typography>
            </Box>
          ) : (
            <>        
              {props?.buildData?.deploy?.startTime &&  (
                <Box component="div" className={classes.box}>
                  <Typography className={classes.buildDate} variant="subtitle2">{getDate(props.buildData.deploy.startTime)} at {getTime(props.buildData.deploy.startTime)}</Typography>
                </Box>
              )}

              {props.buildData?.deploy?.success && (
                <>
                  <Grid container>
                    <Box component="div" className={classNames(classes.box, classes.timingWrapper)}>
                      <CheckCircleIcon className={classNames(classes.icon, classes.successIcon)}/>
                      <Typography className={classNames(classes.buildInText, classes.marginRightS)} variant="subtitle2">Built</Typography>
                    </Box>
    
                    <Box component="div" className={classNames(classes.box, classes.timingWrapper)}>
                      <CloudUploadIcon className={classNames(classes.icon, classes.successIcon, {[classes.disabledIcon as string]: !deployed})}/>
                      <Typography 
                        className={classNames(classes.buildInText, classes.marginRightS)}
                        variant="subtitle2"
                      >
                          {deployed ? 'Deployed' : 'Deploy in progress'}
                      </Typography>
                    </Box>
                  </Grid>
                </>
              )}
    
              {!props.buildData?.deploy?.success && (
                <Grid container>
                  <Box component="div" className={classNames(classes.box, classes.timingWrapper)}>
                    <CancelIcon className={classNames(classes.icon, classes.errorIcon)}/>
                    <Typography className={classNames(classes.buildInText, classes.marginRightS)} variant="subtitle2">Failed</Typography>
                  </Box>
                </Grid>
              )}
            </>
          )} 
        </Grid>
        
        <Grid className={classes.rightWrapper} container direction='column' justify="space-between" item xs={3}>
          <Button onClick={triggerBuild} disabled={props.loading || loading}>Build</Button>
        </Grid>
      </Grid>
    </Card>
  ) : null
}
