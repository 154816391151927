/* eslint-disable no-useless-computed-key */

import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => {
  return createStyles({
    link: {
      display: 'inline-block',
      marginBottom: 3
    },
    buildInText: {
      paddingBottom: 0,
    },
    buildDate: {
      color: 'rgba(99, 100, 99, 0.6)',
      paddingBottom: 0
    },
    icon: {
      height: 20,
      width: 20,
      marginRight: 5,
      marginTop: -4
    },
    successIcon: {
      color: '#2ca72c'
    },
    errorIcon: {
      color: '#FF4242'
    },
    marginRightS: {
      marginRight: 15
    },
    marginTopS: {
      marginTop: 10
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 16
    },
    timingWrapper: {
      marginBottom: 0,
      ['@media (max-width: 500px)']: {
        marginBottom: 16,
      }
    },
    timingWrapperLoading: {
      marginTop: 10
    },
    wrapper: {
      ['@media (max-width: 500px)']: {
        flexDirection: 'column',
        '& > *': {
          width: '100%',
          maxWidth: '100%!important'
        }
      }
    },
    rightWrapper: {
      flex: 0,
      minWidth: 135,
      marginLeft: 'auto'
    },
    leftWrapper: {
      maxWidth: 'calc(100% - 135px)'
    },
    disabledIcon: {
      color: 'rgba(99, 100, 99, 0.6)'
    }
  })
});