
import React, { FormEvent, useState } from "react"
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useStyles } from "./mui-styles";
import { AuthData, IFormField } from "../../../types";
import FormComponent from "../form";
import { ErrorMessage } from "@influencemachinesllc/material-ui-components";
import api from "../../services/api";

const formFields: IFormField[] = [
  {
    name: "username",
    type: "text",
    label: "Username",
    placeholder: "Enter username",
    validate: ['required'],
  },
  {
    name: "password",
    type: "password",
    label: "Password",
    placeholder: "Enter your password",
    validate: ['required'],
  }
]

interface IProps {
  onSignIn: () => void
}

export const SignInForm = (props: IProps) => {
  const classes = useStyles();

  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    setDisableSubmit(true)
    setErrorMessage(undefined)
    
    const formData = new FormData(event.target as HTMLFormElement);

    const data = {
      user: formData.get('username'), 
      pass: formData.get('password')
    } as AuthData

    api({method: 'POST', url: '/login', data})
    .then((token: string) => {
      localStorage.setItem('token', token)
      setDisableSubmit(false)
      props.onSignIn()
    })
    .catch(() => {
      setErrorMessage('Something went wrong. Please, check your credentials')
      setDisableSubmit(false)
    })
  }

  return (
    <div className={classes.wrapper}>
     <Typography className={classes.title} variant="h2">Sign In</Typography>
     <ErrorMessage message={errorMessage}/>
     <FormComponent
        onSubmit={onSubmit}
        fieldsData={formFields}
        formName="signInForm"
        footer={<Button type="submit" className={classes.submitButton} disabled={disableSubmit}>Submit</Button>}
      />
    </div>
  )
}
