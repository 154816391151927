import { createMuiTheme } from '@material-ui/core';
import { getTheme } from '@influencemachinesllc/material-ui-components';

const theme = createMuiTheme({
  ...getTheme({
    overrides: {
      MuiLink: {
        root: {
          transition: '0.1s linear',
          '&:hover': {
            opacity: 0.8,
          }
        }
      },
      MuiAppBar: {
        root: {
          marginBottom: 0
        }
      }
    },
    typography: {
      h1: {
        fontSize: 28
      },
      h3: {
        textAlign: 'left',
        textTransform: 'none',
        paddingTop: 0,
        paddingBottom: 0
      }
    }
  })
});

export default theme;