import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => {
  return createStyles({
    cardWrapper: {
      marginTop: 20,
      marginBottom: 20,
      padding: 34
    }
  })
});