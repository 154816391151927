import React, { FormEvent, useState } from 'react'
import { IFormField, IValidationData } from '../../../types'
import Input from '../input'

export interface IProps {
  fieldsData: IFormField[];
  onSubmit: (data: FormEvent<HTMLFormElement>) => void;
  footer?: JSX.Element;
  formName: string;
}

let formErrors: any = {}
const FormComponent = (props: IProps) => {
  const [formValues, setFormValues] = useState<any>({}) 
  const [showErrors, setShowErrors] = useState(false)

  const isFieldValid = (validationData: IValidationData) => {
    formErrors = {
      ...formErrors, [validationData.name]: !validationData.valid 
    }
  }

  const isFormValid = () => {
    return !props.fieldsData?.find((field: IFormField) => formErrors[field.name])
  }

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setShowErrors(true);
    if(isFormValid())
      props.onSubmit(event)
  }

  const onFormChange = (data: {value: string, name: string}) => {
    setFormValues({...formValues, [data.name]: data.value})
  }

  return (
    <form 
      name={props.formName} 
      onSubmit={onFormSubmit}
      data-testid="form"
    >
      {props.fieldsData?.map((field, i: number) => (
        <Input 
          id={field.name + props.formName + 'Input'}
          key={i}
          name={field.name}
          type={field.type}
          label={field.label}
          placeholder={field.placeholder}
          validate={field.validate}
          isValid={isFieldValid}
          noErrors={!showErrors}
          defaultValue={field.defaultValue}
          onChange={(value: string) => onFormChange({value, name: field.name})}
          confirmationValue={formValues['password'] || formValues['newPassword']}
        />
      ))}
      {props.footer}
    </form>
  
  )
}

export default FormComponent
